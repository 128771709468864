
import Modal from "components/Modal/Modal"
export default {
  name: "home",
  components: {
    Modal
  },
  data() {
    return {
      orderSource: '',
      isLogin: true,
      coname: '',
      expressNumber: '',
      expressNumReg: /^[0-9a-zA-Z\\-\\_]{5,40}$/,
      // autoImage: '',
      comCodeTimeout: null, // 用于防抖
      comCode: '',
      scanable: window.scanable || false,
      showAppDownload: false,
      recman: {},
      sendman: {},
      defaultAddr: {
      },
      availableCompListData: [],
      address2geoData: {},
      preLoc: {},
      addrBook: [],
      tabList: [
        {
          title: "寄快递",
          path: 'dspatch'
        },
        {
          title: "批量寄件",
          path: "dispatchbatch"
        },
        {
          title: "寄大件",
          path: "normal"
        }
      ],
      comLogoList: [
        'https://cdn.kuaidi100.com/images/all/56/jd.png',
        'https://cdn.kuaidi100.com/images/all/56/yuantong_new.png',
        'https://cdn.kuaidi100.com/images/all/56/shentong.png',
        'https://cdn.kuaidi100.com/images/all/56/zhongtong.png',
        'https://cdn.kuaidi100.com/images/all/56/shunfeng.png',
      ],
      historyList: [
        {
          "createTime": "2024-11-21 14:59:06",
          "lastModify": "2024-11-21 14:59:06",
          "rssType": "NORSS",
          "rssStatus": "NORSS",
          "smsType": 0,
          "tradeCreated": null,
          "cargo": null,
          "transStatus": "TRANS",
          "id": 3000018776,
          "smsStatus": null,
          "comName": "京东",
          "userId": 19415942,
          "sendMobile": null,
          "recordType": null,
          "exchange": null,
          "kuaidiNum": "78852899853964",
          "sendName": null,
          "updateTime": 1732172346906,
          "recMobile": null,
          "status": 0,
          "tabId": "TRANS",
          "orderNum": null,
          "kuaidiCom": "jd",
          "unupdateTime": null,
          "product": null,
          "syncUpdateTime": null,
          "intransitTime": null,
          "source": null,
          "receivingCity": null,
          "sendAddr": null,
          "recAddr": null,
          "cargoImgUrl": null,
          "manualStatus": null,
          "comment": null,
          "shippingCity": null,
          "recName": null
      },
      {
          "createTime": "2024-11-19 16:50:59",
          "lastModify": "2024-11-19 16:50:59",
          "rssType": "NORSS",
          "rssStatus": "NORSS",
          "smsType": 0,
          "tradeCreated": null,
          "cargo": null,
          "transStatus": "TRANS",
          "id": 3000018717,
          "smsStatus": null,
          "comName": "京东",
          "userId": 19415942,
          "sendMobile": null,
          "recordType": null,
          "exchange": null,
          "kuaidiNum": "2674562236",
          "sendName": null,
          "updateTime": 1732006259049,
          "recMobile": null,
          "status": 0,
          "tabId": "TRANS",
          "orderNum": null,
          "kuaidiCom": "jd",
          "unupdateTime": null,
          "product": null,
          "syncUpdateTime": null,
          "intransitTime": null,
          "source": null,
          "receivingCity": null,
          "sendAddr": null,
          "recAddr": null,
          "cargoImgUrl": null,
          "manualStatus": null,
          "comment": null,
          "shippingCity": null,
          "recName": null
      },
      {
        "createTime": "2024-11-19 16:50:59",
        "lastModify": "2024-11-19 16:50:59",
        "rssType": "NORSS",
        "rssStatus": "NORSS",
        "smsType": 0,
        "tradeCreated": null,
        "cargo": null,
        "transStatus": "TRANS",
        "id": 3000018717,
        "smsStatus": null,
        "comName": "京东",
        "userId": 19415942,
        "sendMobile": null,
        "recordType": null,
        "exchange": null,
        "kuaidiNum": "2674562236",
        "sendName": null,
        "updateTime": 1732006259049,
        "recMobile": null,
        "status": 0,
        "tabId": "TRANS",
        "orderNum": null,
        "kuaidiCom": "jd",
        "unupdateTime": null,
        "product": null,
        "syncUpdateTime": null,
        "intransitTime": null,
        "source": null,
        "receivingCity": null,
        "sendAddr": null,
        "recAddr": null,
        "cargoImgUrl": null,
        "manualStatus": null,
        "comment": null,
        "shippingCity": null,
        "recName": null
    },
      ],
      popupShow: false,
      wxLink: ''
    }
  },
  computed: {
    autoImage() {
      return 'https://cdn.kuaidi100.com/images/all/36/' + this.comCode + '.png'
    },
    showPrice() {
      if(this.availableCompListData.length === 0 ){
        return 0
      }
      return this.availableCompListData.reduce((minPrice, currentItem) => {
        return (Number(currentItem.totalprice) < minPrice) ? currentItem.totalprice : minPrice;
      }, Infinity)
    }
  },
  created() {
    this.getconame()
    this.login(async () => {
      console.log('登录了')
      this.islogin = true
      await this.getAddrBook()
      this.initAddr()
    },()=>{
      this.initAddr() 
    })
  },
  methods: {
    getconame() {
      // 获取当前页面的完整 URL
      let data = this.$store.state.globalData
      this.coname = data?.coname || ''
      console.log('coname',this.coname);
    },
    scanCode() {
        if (this.scanable && typeof window.scan == "function") {
          //第三方开放的扫码功能
          scan(this)
        } else {
          this.showAppDownload = true
        }
    },
    goAppDownload() {
      location.href = "https://m.kuaidi100.com/app/download.html"
    },
    scanClear() {
      this.expressNumber = ""
      this.comCode = ""
    },
    identifyCom() {
      clearTimeout(this.comCodeTimeout)
      var num = this.expressNumber // 缓存发起查询请求时候的单号
      this.comCodeTimeout = setTimeout(() =>{
        this.$http.post("/autonumber/autoComNum", {
          data: {
            text: this.expressNumber,
          }
          }).then((res)=>{
            if (num === this.expressNumber) {
              // 避免先请求后返回问题，确保请求参数是当前输入框的值
              var comCode = res.auto && res.auto[0] && res.auto[0].comCode
              if (comCode) {
                this.comCode = comCode
              } else {
                this.comCode = ""
              }
            }
        }).catch(()=>{
          this.comCode = ""
        })
      }, 300) // 防抖，和产品约定好的300ms
    },
    search(num, com, event) {
      com = com || window.com
      if(!num) {
        return
      }
      this.expressNumber = num || this.expressNumber
      if (!/^[\da-z]+$/i.test(this.expressNumber)) {
        this.$toast("请输入正确的快递单号")
        return
      }

      this.$http.post("/autonumber/autoComNum", {
        data: {
          text: this.expressNumber,
        },
        handleFail: false,
      }).then((res)=> {
        // res = res.responseJSON || {}
        var comCode = res.auto && res.auto[0] && res.auto[0].comCode
        this.checkCom(comCode, com)
        // this.getWxLink()
      }).catch(()=>{
        this.goResultPage(com)
      })
    },
    goResultPage: function (com) {
      var flag = /m.kuaidi100.com\/app\/?/.test(location.href) || this.coname
      var url = flag ? "/app/query/?coname=" + this.coname + "&nu=" : "/result.jsp?nu="
      if (this.expressNumber) {
        location.href = url + this.expressNumber + (com ? "&com=" + com : "")
        window.sessionStorage.setItem("query_times", queryTimes)
        window.localStorage.setItem("dayQueryTimes", dayQueryTimes)
      }
    },
    checkCom: function (comCode, com) {
      if (comCode === "zhongtong" || comCode === "yunda") {
        this.showPopup(comCode)
      } else {
        this.goResultPage(com)
      }
    },
    showPopup() {
      this.popupShow = true
    },
    jumpToWX() {
      if(this.wxLink) {
        const textToCopy = this.expressNumber;
        // 使用 Clipboard API 进行复制
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
              tips("复制成功了~")
            })
            .catch(err => {
              console.error('复制失败: ', err)
            })
        window.location.href = this.wxLink;
      }
    },
    getWxLink() {
      // 获取微信小程序link
      const baselink = "weixin://dl/business/?appid=wx6885acbedba59c14&path=pages/index/index"
      const query = "source=touch&openSource=H5"
      vm.wxLink = baselink + "&" + `query=${encodeURIComponent(query)}`
    },
    async initAddr() {
      if(this.$store.state.dispatch.sendman.name || this.$store.state.dispatch.recman.name) {
        this.sendman = this.$store.state.dispatch.sendman
        this.recman = this.$store.state.dispatch.recman
        this.availableCompList()
        return
      }
      if(this.isLogin && this.addrBook.length) {
        if(this.addrBook[0].addrs[0].def) { //默认地址
          this.defaultAddr = this.getData(this.addrBook[0])
        } else if (localStorage.getItem('dispatch_sendman')) {
          this.defaultAddr = JSON.parse(localStorage.getItem('dispatch_sendman')) || {}
        } else {
          await this.getCurrAddress()
        }
      } else {
        await this.getCurrAddress()
      }
      this.sendman = this.defaultAddr
      this.$store.commit('dispatch/SET_SENDMAN', this.defaultAddr)
      this.recman = JSON.parse(localStorage.getItem('dispatch_recman')) || this.defaultAddr || {}
      this.$store.commit('dispatch/SET_RECMAN', this.recman)
      // this.address2geo()
      // this.availableCom4BrandOrigin()
      this.availableCompList()
    },
    getData(data) {
      const addr = data.addrs[0] || {}
      return {
        id: data.id,
        name: data.name,
        mobile: data.mobile || '',
        phone: data.mobile || '',
        // tel: data.tel || '',
        latitude: data.latitude || '',
        longitude: data.longitude || '',
        tag: data.tag,
        province: addr.province,
        city: addr.city || '',
        district: addr.district || '',
        addr: addr.addr,
        address: addr.addr,
        mixLocation: data.mixLocation || '',
        xzqName: `${addr.province || ''},${addr.city || ''},${addr.district || ''}`
      }
    },
    getCurrAddress() { // 根据ip获取省市
      const url = '/apicenter/openapi.do?method=getCurrAddress'
      const params = {
        handleFail: false
      }
      return new Promise((resolve, reject) => {
        this.$http.post(url, params).then((res) => {
          let city = res.data.city || ''
          const province = res.data.province.replace('省', '')
          if (['北京市', '天津市', '上海市', '重庆市'].indexOf(city) > -1) {
            city = city.replace('市', '')
          }
          if (city && province) {
            // this.defaultAddr.xzqName = `${province},${city}`
            this.$set(this.defaultAddr, 'xzqName', `${province},${city}`)
          } else {
            // this.defaultAddr.xzqName = `广东省，深圳市`
            this.$set(this.defaultAddr, 'xzqName', `广东省，深圳市`)
          }
        }).catch(()=>{
          this.$set(this.defaultAddr, 'xzqName', `广东省，深圳市`)
        }).finally(()=>{
          resolve()
        })
      })
      
    },
    async address2geo () { // 下单优化 检测经纬度
      let url = '/apicenter/xcx.do?method=address2geo'
      const params = {
        data: {
          address: this.defaultAddr.xzqName.split(',').join() + (this.sendman.address || '')
        }
      }
      await this.$http.post(url, params).then((res)=> {
        this.address2geoData = res.data
      })
    },
    availableCom4BrandOrigin () { // 下单优化 快递公司列表 kdkd
      let url = '/apicenter/order.do?method=availableCom4Brand'
      // let latitude = this.chooseNow == 0 ? this.locationInfo.lat : this.resetLat ? this.resetLat : this.sendman.latitude
      // let longitude = this.chooseNow == 0 ? this.locationInfo.lng : this.resetLat ? this.resetLng : this.sendman.longitude
      const params = {
        data: {
          recxzq: this.defaultAddr.xzqName.split(',').join(),
          recAddr: this.defaultAddr.address || '',
          raddrid: this.defaultAddr.id || '',
          saddrid: this.defaultAddr.id || '',
          weight: 1,
          cargo: "日用品",
          sendxzq: this.defaultAddr.xzqName.split(',').join(),
          sendAddr: this.defaultAddr.address,
          cargodesc: '',
          latitude: this.defaultAddr.latitude || '23.16',
          longitude: this.defaultAddr.longitude || '113.23',
          inviteSign:  '',
          orderSource: this.orderSource || ''
        }
      }
      this.$http.post(url, params).then((res)=> {
        this.availableCom4BrandList = res.data
      }).catch(()=>{
        this.availableCom4BrandList = []
      })
    },
    availableCompList() { // 快递公司列表
      // if(!this.defaultAddr.xzqName) return
      const url = '/apicenter/order.do?method=availableCompList'
      const params = {
        data: {
          sendxzq: this.sendman.xzqName ||this.defaultAddr.xzqName || '',
          recxzq: this.recman.xzqName || this.defaultAddr.xzqName || '',
          weight: 1,
        }
      }
      this.$http.post(url, params).then((res)=> {
        this.availableCompListData = res.data || []
      }).catch(err => {
				this.$toast(res?.data?.message || '系统繁忙， 请稍后再试')
				this.availableCompListData = []
			})
    },
    showSelectContact (type) { // 选择地址
      if (!this.islogin) {
        return this.$router.push({
          name: "login"
        })
      }
      // this.broadcast()
      this.$router.push({
        name: "addressDetail",
        params: {
          detail: Object.assign({}, this[type]),
          source: "homepage",
          tag: type,
        }
      })
    },
    showContact(type) {
      if (!this.islogin) {
        return this.$router.push({
          name: "login"
        })
      }
      // this.broadcast() // 接收地址填充的通知
      this.$router.push({
        name: "addressList",
        params: {
          source: "homepage"
        },
        query: {
          from: "homepage",
          tag: type,
          selectId: this[type].id || -1
        }
      })
    },
    // broadcast() {
    //   this.$broadcastChannel.$on("fillContact", (addr, type) => {
    //     this.selectContact(addr, type)
    //     this.$broadcastChannel.$off("fillContact")
    //   })
    // },
    // async selectContact (data, type) { // 选择地址
    //   if (!data) return
    //   if (/台湾|香港|澳门/.test(data.xzqName) && type == 'sendman') {
    //     this.$confirm({
    //       content: "抱歉，目前暂不支持港澳台和国际件。服务正在筹备中，敬请期待！",
    //       type: 'alert',
    //       confirmText: "我知道了"
    //     })
    //     return
    //   }
    //   if(data.address.length < 4) {
    //     return this.$toast("详细地址不能少于4个字")
    //   }
    //   this[type] = data
    //   if (type === 'sendman') {
    //     await this.address2geo() // 修改寄件人 去查地址经纬度
    //     localStorage.setItem('dispatch_sendman', JSON.stringify(data)) 
    //   } else {
    //     localStorage.setItem('dispatch_recman', JSON.stringify(data))
    //   }
    // },
    godispatch() {
      this.$router.push({
        name: "dispatch",
        query: {
          orderSource: this.orderSource || '',
          from: "homepage"
        }
      })
    },
    loginCallback () {
      this.islogin = true
    },
    getAddrBook() {
      const url = '/centeraddress/getSentContact'
      const params = {
        data: {
          tag: 3,
          offset: 1,
          limit: 10,
          contactType: 'ALL'
        },
        needAuth: true
      }
      return new Promise((resolve, reject) => {
        this.$http.post(url, params).then(async(res) => {
          const list = res.data || []
          if (list.length) {
            this.addrBook = list
          }
        }).catch(() => {}).finally(() => {
          resolve()
        })
      })
    },
    clickTab(index) {
      if(index === 0) {
        return
      } else if(index === 1) {
        this.$router.push({ name: this.tabList[index].path})
      } else if(index === 2) {
        const query = {
          orderSource: this.orderSource,
          sign: 'b1lSLTzU5pheMarket',
          heavy: 1,
        }
        this.$router.push({ name: this.tabList[index].path, query})
      }
    }
  },
  filters: {
    calctime: function(time) {
      if(!time) return ''
      var timeStr = time.replace(/-/g,'/');
      var dateObj = new Date(timeStr), now = new Date()
      var timeObj = {year: dateObj.getFullYear(), month: dateObj.getMonth() + 1, day: dateObj.getDate(),hours: dateObj.getHours(), minutes: dateObj.getMinutes()},
        nowObj = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()},
        timestamps = Math.floor((now.getTime() - dateObj.getTime()) / 1000)

      if(timestamps < 60){
        return '刚刚'
      } else if(timestamps < 3600){
        return ~~(timestamps / 60) + '分钟前'
      } else if(nowObj.year == timeObj.year && nowObj.month == timeObj.month && nowObj.day - timeObj.day == 0){
        return '今天' + timeObj.hours + ':' + timeObj.minutes
      } else if(nowObj.year == timeObj.year && nowObj.month == timeObj.month && nowObj.day - timeObj.day == 1){
        return '昨天' + timeObj.hours + ':' + timeObj.minutes
      } else if(nowObj.year == timeObj.year && nowObj.month == timeObj.month && nowObj.day - timeObj.day == 2){
        return '前天' + timeObj.hours + ':' + timeObj.minutes
      } else if(nowObj.year == timeObj.year){
        return timeObj.month + '月' + timeObj.day + '日'
      } else {
        return timeObj.year + '年' + timeObj.month + '月' + timeObj.day + '日'
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from && from.name == 'dispatch') {
        console.log('6666');
        vm.initAddr()
      }
    })
  },
}